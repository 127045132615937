<template>
  <section class="section">
    <div class="row">
      <div class="col">
        <Panel type="sheet">
          <template v-slot:headline>店舗詳細設定</template>
          <template v-slot:body>
            <div class="form">
              <section class="form-section">
                <p class="form-headline">詳細項目</p>
                <transition-group class="additionForm" name="fadeSlide" tag="div">
                  <div class="additionForm-item" v-for="(detail, index) in form.details" :key="index">
                    <button class="additionForm-delete" type="button" @click="removeDetail(index)"><i class="aikon aikon-cross"></i></button>
                    <FormRow>
                      <template v-slot:label>項目名</template>
                      <template v-slot:labelNote>（50文字以内）</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <input
                            :class="{ 'form-control': true, 'is-error': errors[`title${index}`] }"
                            type="text"
                            name="title"
                            v-trim
                            maxlength="50"
                            v-maxlength
                            v-model="form.details[index].title"
                          />
                        </div>
                      </template>
                    </FormRow>
                    <FormRow>
                      <template v-slot:label>内容</template>
                      <template v-slot:labelNote>（100文字以内）</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <textarea
                            :class="{ 'form-control form-textarea': true, 'is-error': errors[`content${index}`] }"
                            name="content"
                            v-trim
                            maxlength="100"
                            v-maxlength
                            v-model="form.details[index].content"
                          ></textarea>
                        </div>
                      </template>
                    </FormRow>
                  </div>
                </transition-group>
                <button class="btn btn-bd-main" type="button" @click="addDetail">項目追加</button>
              </section>
              <section class="form-section">
                <p class="form-headline">SNS設定</p>
                <FormRow>
                  <template v-slot:label>ホームページ</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <input
                        :class="{ 'form-control': true, 'is-error': errors.site }"
                        type="text"
                        name="site"
                        placeholder="https://"
                        v-model="form.sns.site"
                        v-trim
                        maxlength="250"
                        v-maxlength
                      />
                    </div>
                  </template>
                </FormRow>
                <FormRow>
                  <template v-slot:label>メールアドレス</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <input
                        :class="{ 'form-control': true, 'is-error': errors.email }"
                        type="text"
                        name="email"
                        v-email
                        v-trim
                        v-maxlength
                        maxlength="50"
                        v-model="form.sns.email"
                      />
                    </div>
                  </template>
                </FormRow>
                <FormRow>
                  <template v-slot:label>Facebook</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <input
                        :class="{ 'form-control': true, 'is-error': errors.facebook }"
                        type="text"
                        name="facebook"
                        placeholder="https://"
                        v-model="form.sns.facebook"
                        v-trim
                        maxlength="250"
                        v-maxlength
                      />
                    </div>
                  </template>
                </FormRow>
                <FormRow>
                  <template v-slot:label>X（Twitter）</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <input
                        :class="{ 'form-control': true, 'is-error': errors.twitter }"
                        type="text"
                        name="twitter"
                        placeholder="https://"
                        v-model="form.sns.twitter"
                        v-trim
                        maxlength="250"
                        v-maxlength
                      />
                    </div>
                  </template>
                </FormRow>
                <FormRow>
                  <template v-slot:label>Instagram</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <input
                        :class="{ 'form-control': true, 'is-error': errors.instagram }"
                        type="text"
                        name="instagram"
                        placeholder="https://"
                        v-model="form.sns.instagram"
                        v-trim
                        maxlength="250"
                        v-maxlength
                      />
                    </div>
                  </template>
                </FormRow>
                <FormRow>
                  <template v-slot:label>LINE</template>
                  <template v-slot:content>
                    <div class="form-content-row">
                      <input
                        :class="{ 'form-control': true, 'is-error': errors.line }"
                        type="text"
                        name="line"
                        placeholder="https://"
                        v-model="form.sns.line"
                        v-trim
                        maxlength="250"
                        v-maxlength
                      />
                    </div>
                  </template>
                </FormRow>
              </section>
            </div>
          </template>
        </Panel>
      </div>
    </div>
  </section>
</template>

<script>
import { requiredIf, maxLength, email } from 'vuelidate/lib/validators';
//helpers
import { isUrl } from '@/helpers/validators';
//mixins
import shop from '@/mixins/module/shop';
//components
import FormRow from '@/components/FormRow.vue';

export default {
  name: 'ShopSettingDetail',
  data: function() {
    return {
      form: {
        details: [
          { title: '', content: '' }
        ],
        sns: {
          site: '',
          email: '',
          facebook: '',
          twitter: '',
          instagram: '',
          line: ''
        }
      },
    };
  },
  components: {
    FormRow,
  },
  mixins: [shop],
  validations: {
    form: {
      details: {
        $each: {
          title: {
            maxLength: maxLength(50),
            required: requiredIf(function (detailItem) {
              return detailItem.content ? true : false;
            })
          },
          content: {
            maxLength: maxLength(100),
            required: requiredIf(function (detailItem) {
              return detailItem.title ? true : false;
            })
          },
        }
      },
      sns: {
        site: { isUrl, maxLength: maxLength(250) },
        email: { email, maxLength: maxLength(50) },
        facebook: { isUrl, maxLength: maxLength(250) },
        twitter: { isUrl, maxLength: maxLength(250) },
        instagram: { isUrl, maxLength: maxLength(250) },
        line: { isUrl, maxLength: maxLength(250) },
      }
    }
  },
  created() {
    this.$parent.editPage = 'detail';
  },
  methods: {
    addDetail: function() {
      this.form.details.push({ title: '', content: '' });
    },
    removeDetail(index) {
      this.form.details.splice(index, 1);
      this.errors[`title${index}`] = false;
      this.errors[`content${index}`] = false;
    },
    settingDetail() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'shopSetting');
        return false;
      } else {
        const convertedForm = {
          details: this.form.details.filter(item => item.title !== '' && item.content !== ''),
          sns: this.form.sns,
        }
        return convertedForm;
      }
    }
  },
};
</script>
